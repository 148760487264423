import React, { useState } from "react";

interface DashboardMetricProps {
    title: String;
    metric: String;
    metricSubtext?: String;
}

const DashboardMetric: React.FC<DashboardMetricProps> = ({ title, metric, metricSubtext }) => {
    return (
        <div className="tw-w-2/5 lg:tw-w-[250px] tw-h-[150px] lg:tw-h-[250px] tw-max-w-full tw-border-2 tw-rounded-lg tw-border-brown tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-4">
            <h3 className="tw-text-normal lg:tw-text-xl tw-font-bold tw-text-center">
                {title}
            </h3>
            <h2 className="tw-text-lg lg:tw-text-3xl tw-mt-4 tw-font-bold">
                {metric} {
                    metricSubtext ?
                    <span className="tw-text-lg">{metricSubtext}</span>
                    :
                    null
                }
            </h2>
        </div>
    )
};


const DASHBOARD_RANGES = {
    WEEK: "WEEK",
    MONTH: "MONTH",
    YEAR: "YEAR",
};

const Dashboard = () => {
    const [dashboardRange, setDashboardRange] = useState(DASHBOARD_RANGES.WEEK);

    return (
        <div>
            <div>
                <h3 className="tw-text-xl tw-font-bold">Hello!</h3>
            </div>

            <div className="tw-flex tw-flex-wrap tw-max-w-[250px] tw-justify-center tw-bg-white tw-rounded-lg tw-p-2 tw-border tw-border-brown tw-mt-8">
                <div className="tw-flex tw-justify-space-around">
                    <button className="tw-px-5" onClick={() => {
                        setDashboardRange(DASHBOARD_RANGES.WEEK);
                    }}>
                        Week
                    </button>
                    <button 
                    onClick={() => {
                        setDashboardRange(DASHBOARD_RANGES.MONTH);
                    }}
                    className="tw-border-l-2 tw-border-r-2 tw-border-gray-400 tw-px-5">
                        Month
                    </button>
                    <button 
                    onClick={() => {
                        setDashboardRange(DASHBOARD_RANGES.YEAR);
                    }}
                    className="tw-px-5">
                        Year
                    </button>
                </div>
            </div>


            <div>
                {
                    dashboardRange === DASHBOARD_RANGES.WEEK ?
                    <p className="tw-text-lg tw-font-bold tw-my-4">
                        {new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString()} - {new Date().toLocaleDateString()}
                    </p>
                    : dashboardRange === DASHBOARD_RANGES.MONTH ?
                    <p className="tw-text-lg tw-font-bold tw-my-4">
                        {new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleDateString()} - {new Date().toLocaleDateString()}
                    </p>
                    : dashboardRange === DASHBOARD_RANGES.YEAR ?
                    <p className="tw-text-lg tw-font-bold tw-my-4">
                        {new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toLocaleDateString()} - {new Date().toLocaleDateString()}
                    </p>
                    : null
                }
            </div>

            <div className="tw-mt-8 tw-flex tw-gap-2 lg:tw-gap-8 tw-flex-wrap">
                <DashboardMetric
                    title="Total Revenue"
                    metric="$0"
                    metricSubtext="USD"
                />
                <DashboardMetric
                    title="Profile Visits"
                    metric="0"
                />
                <DashboardMetric
                    title="Message Requests"
                    metric="0"
                />
            </div>

            <div className="tw-mt-8">
                <div className="tw-w-full tw-border-4 tw-rounded-lg tw-border-blue tw-p-4">
                    <h3 className="tw-text-xl tw-font-bold">
                        Upcoming Trips
                    </h3>
                    <p className="tw-mt-4">
                        Looks like you don't have any upcoming trips booked.
                    </p>
                </div>
            </div>
        </div>
    )
};

export default Dashboard;